import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Button, Alert, Card, ListGroup, ListGroupItem, Progress, Badge } from 'reactstrap';
import { useParams } from 'react-router-dom';
import UploadManager from 'lib/UploadManager';
import t from 'lib/translate';
import { DOCUMENT_TYPE_EMOJI } from 'config/Constants';
import CollapsedError from 'components/collapsedError/CollapsedError';
import DeleteSpinnerButton from 'components/deleteSpinnerButton/DeleteSpinnerButton';

const UploadItem = observer(function ({ file, uploadMananger }) {
  let errorMessage;
  let deleteButton;

  if (file.state === 'failed') {
    if (file.error) {
      errorMessage = <CollapsedError error={file.error} />;
    }

    const handleDeleteClick = () => {
      uploadMananger.removeFile(file);
    };

    deleteButton = <DeleteSpinnerButton onClick={handleDeleteClick} />;
  }

  return (
    <ListGroupItem color={file.state === 'failed' ? 'error' : ''}>
      <div className="d-flex align-items-center align-content-center">
        <div>
          <span>
            {DOCUMENT_TYPE_EMOJI[file.documentType]} {file.nativeFile.name}
          </span>{' '}
          {file.state === 'failed' ? <Badge color="danger">failed</Badge> : null}{' '}
          {file.documentProcessor === 'v2' ? <Badge color="info">new recognition</Badge> : null}
        </div>
        {deleteButton}
      </div>
      <Progress value={100 * file.progress} animated={file.progress === 1} className="mt-1" style={{ height: '3px' }} />
      {errorMessage}
    </ListGroupItem>
  );
});

UploadItem.propTypes = {
  file: PropTypes.object.isRequired,
  uploadMananger: PropTypes.object.isRequired,
};

function UploadFileList({ uploadMananger }) {
  const files = uploadMananger.files;

  if (files.length === 0) {
    return (
      <Alert fade={false} color="info">
        {t('No files are currently being uploaded')}
      </Alert>
    );
  } else {
    return (
      <Card
        style={{
          maxHeight: '200px',
          overflow: 'auto',
        }}
      >
        <ListGroup flush>
          {files.map((file) => (
            <UploadItem key={file.key} file={file} uploadMananger={uploadMananger} />
          ))}
        </ListGroup>
      </Card>
    );
  }
}

UploadFileList.propTypes = {
  uploadMananger: PropTypes.object.isRequired,
};

const UploadList = observer(() => {
  const params = useParams();
  const dossierId = parseInt(params.dossierId, 10);
  const uploadMananger = UploadManager.get(dossierId);

  function handleRetryAll() {
    uploadMananger.retryAll();
  }

  const failedUploads = uploadMananger.files.filter((file) => {
    return file.state === 'failed';
  });

  return (
    <>
      <h2>
        <span>
          {t('Uploads')} ({uploadMananger.files.length})
        </span>

        {failedUploads.length > 0 ? (
          <Button color="primary" className="ml-2" onClick={handleRetryAll}>
            {t('Retry failed uploads')}
          </Button>
        ) : null}
      </h2>

      <UploadFileList uploadMananger={uploadMananger} />
    </>
  );
});

export default UploadList;
