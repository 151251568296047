import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import ServerTasksProvider from 'lib/ServerTasksProvider';

const UPLOAD_CONCURRENCY = 3;

// We need a clean axios client for Azure without the default headers and interceptors.
const uploadClient = axios.create();

export default class UploadManager {
  dossierId = 0;
  files = [];

  constructor(dossierId) {
    makeAutoObservable(this);
    this.dossierId = dossierId;
  }

  addFiles(files) {
    for (const file of files) {
      file.progress = 0;
      file.state = 'waiting';
      file.error = null;
    }

    this.files.push(...files);
    this.fillUploadQueue();
  }

  fillUploadQueue() {
    let numUploading = 0;

    // Make sure #UPLOAD_CONCURRENCY files are currently uploading.
    for (let i = 0; i < this.files.length && numUploading < UPLOAD_CONCURRENCY; i++) {
      let file = this.files[i];

      if (file.state === 'uploading') {
        numUploading++;
      } else if (file.state === 'waiting') {
        file.state = 'uploading';
        this.uploadFile(file);
        numUploading++;
      }
    }
  }

  retryAll() {
    for (const file of this.files) {
      if (file.state === 'failed') {
        file.progress = 0;
        file.state = 'waiting';
      }
    }

    this.fillUploadQueue();
  }

  removeFile(file) {
    this.files = this.files.filter((f) => f !== file);
  }

  handleProgress(e, file) {
    file.progress = e.loaded / e.total;
  }

  handleUploadSuccess(file) {
    file.state = 'done';
    this.files = this.files.filter((f) => f !== file);

    // We know that a new file was just added, immediately refresh the server tasks.
    ServerTasksProvider.tryGet(this.dossierId)?.fetch();
  }

  handleUploadError(err, file) {
    file.error = err.toString();
    file.state = 'failed';
  }

  async uploadFile(file) {
    try {
      if (file.nativeFile.size === 0) {
        throw new Error(
          `Cannot upload file with size 0. Maybe OneDrive hasn't synced the file yet? Remove the upload and add the file again.`
        );
      }

      // Get the signed URL from the server so we can upload directly to Azure Blob.
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.dossierId}/upload/create-signed-url`
      );

      // Upload directly to Azure Blob using the signed URL.
      await uploadClient.put(response.data.url, file.nativeFile, {
        headers: response.data.headers,
        onUploadProgress: (e) => this.handleProgress(e, file),
      });

      // Let the server know the upload is done and start processing the file.
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.dossierId}/upload`, {
        id: response.data.id,
        documentType: file.documentType,
        documentProcessor: file.documentProcessor,
        privateContraAccount: file.privateContraAccount,
        fileName: file.nativeFile.name,
        fileType: file.nativeFile.type,
      });

      this.handleUploadSuccess(file);
    } catch (err) {
      this.handleUploadError(err, file);
    } finally {
      this.fillUploadQueue();
    }
  }

  static instances = new Map();

  static get(dossierId) {
    if (UploadManager.instances.has(dossierId)) {
      return UploadManager.instances.get(dossierId);
    }

    const manager = new UploadManager(dossierId);

    UploadManager.instances.set(dossierId, manager);

    return manager;
  }

  static hasActiveUploads() {
    for (const manager of UploadManager.instances.values()) {
      if (manager.files.length > 0) {
        return true;
      }
    }

    return false;
  }
}

window.addEventListener('beforeunload', (e) => {
  if (UploadManager.hasActiveUploads()) {
    e.preventDefault();
    e.returnValue = '';
  }
});
