import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';

export default function DeleteSpinnerButton({ onClick }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = () => {
    if (!isProcessing) {
      setIsProcessing(true);
      onClick();
    }
  };

  let buttonContent;

  if (isProcessing) {
    buttonContent = <Spinner size="sm" />;
  } else {
    buttonContent = <FaTrash />;
  }

  return (
    <div className="ml-auto">
      <Button className="ml-1" disabled={isProcessing} onClick={handleClick}>
        {buttonContent}
      </Button>
    </div>
  );
}

DeleteSpinnerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
