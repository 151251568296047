import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

export default function CollapsedError({ error }) {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const message = showFullMessage ? error : error.split('\n')[0];

  const handleErrorClick = () => {
    setShowFullMessage(true);
  };

  return (
    <Alert fade={false} className="mt-2 mb-1" color="danger" onClick={handleErrorClick}>
      <pre className="mb-0" style={{ 'white-space': 'pre-wrap' }}>
        {message}
      </pre>
    </Alert>
  );
}

CollapsedError.propTypes = {
  error: PropTypes.string.isRequired,
};
